window.getAnalytics = function(groupId, indicatorId, graphId, tableId, tbId) {
  $.getJSON('/dashboard/data?group_id='+groupId+'&indicator_id='+indicatorId, function(response) {
    loadTable(tbId, response);
    loadHighCharts(graphId,tbId,response)
  });  
}

function loadTable(divId, response) {
  let tableContent = '';
  if (response.nesting == 1) {
    response.data.map(function(record) {
      tableContent += `<tr><td>${record.label}</td><td>${record.count}</td></tr>`
    });
  } else {
    tableContent += `<tr><th></th>` + response.columns.map(function(c) {return `<th>${c}</th>`}) + `</tr>`;
    response.data.map(function(record) {
      tableContent += `<tr><th>${record.label}</th>` + record.data.map(function(r) { return `<td>${r.count}</td>`;}) + `</tr>`;
    });
  }
  $(`#${divId}`).html(tableContent);
}

function getUnique(value, index, self) {
  return self.indexOf(value) === index;
}

window.conditionalSelect = function(field_id,val) {
  $("#section_"+field_id+"_"+val).show();
}

window.handleBtnAdd = function() {
  //What should I do here?
  var selectedText = $("#field").find("option:selected").text();
  var field_id = $("#field").val();
  var newRow = "<div class='row'><input type='hidden' name='field_id[]' value='"+field_id+"'/><div class='col-3'>" + selectedText + "</div><div class='col-3'>";
  newRow += "<select name='operator" + field_id +"' class='form-control'><option value='='>Equal to</option><option value='between'>Between</option><option value='like'>Contains</option><option value='>='>GT OR EQ</option><option value='<='>LT OR EQ</option><option value='>'>GT</option><option value='<'>LT</option></select></div>";
  newRow += "<div class='col-6'><input type='text' class='form-control' name='value" + field_id +"'/></div></div>";
  $("#conditions").append(newRow);
}

function loadHighCharts(divId, tableId, response) {
  let columns = [];

  let data = [];
  let series = [];

  if (response.nesting == 2) {
    columns = response.columns;
    response.data.map(function(record) {
      data = record.data.map(function(r) { return r.count;});
      series.push({name: record.label, data: data});
     });
  } else {
    data = response.data.map(function(record) {return record.count })
    columns = response.data.map(function(record) {return record.label });
    series = [{name: response.xaxis, data: data}];
  }
  Highcharts.chart(divId, {
    chart: {
        type: 'column'
    },
    title: {
        text: ''
    },
    yAxis: {
        allowDecimals: false,
        title: {
            text: response.yaxis
        }
    },
    xAxis: {
      categories: columns
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.0f}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
    },
    plotOptions: {
        column: {
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    series: series
  });
}

window.smallDevices = function () {
  if (document.getElementById('left-nav1').style.display === 'none') {
      document.getElementById('left-nav1').style.display = 'block';
      document.getElementById('small-devices').style.display = 'block';
      document.getElementById('dashboard-tittle').style.display = 'none';
      document.getElementById('settings').style.display = 'none';
      document.getElementById('settings-icon').style.display = 'block';

  } else {
      document.getElementById('left-nav1').style.display = 'none';
      document.getElementById('small-devices').style.display = 'block';
      document.getElementById('dashboard-tittle').style.display = 'block';
      document.getElementById('settings').style.display = 'block';
      document.getElementById('settings-icon').style.display = 'none';
  }
}

window.hideSideBar = function () {
  if (document.getElementById('left-nav1').style.display === 'block') {
    document.getElementById('left-nav1').style.display = 'none';
  } 
}

function loadGroupedHighCharts(divId, tableId, response) {
  
  Highcharts.chart(divId, {
    chart: {
        type: 'column'
    },
    title: {
        text: ''
    },
    yAxis: {
        allowDecimals: false,
        title: {
            text: response.yaxis
        }
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
    },
    plotOptions: {
        column: {
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    series: [{
        name: 'Tokyo',
        data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4]

    }, {
        name: 'New York',
        data: [83.6, 78.8, 98.5, 93.4, 106.0, 84.5, 105.0, 104.3, 91.2, 83.5, 106.6, 92.3]

    }, {
        name: 'London',
        data: [48.9, 38.8, 39.3, 41.4, 47.0, 48.3, 59.0, 59.6, 52.4, 65.2, 59.3, 51.2]

    }, {
        name: 'Berlin',
        data: [42.4, 33.2, 34.5, 39.7, 52.6, 75.5, 57.4, 60.4, 47.6, 39.1, 46.8, 51.1]

    }]
  });
}